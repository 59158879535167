import React from 'react'

const NotFoundPage = () => (
  <section className='content'>
   	<h1 className='nav-link' style={{paddingTop: '25%', color: '#761ed6'}}>
	      404
	</h1>
	we couldn't find what your looking for
  </section>
)

export default NotFoundPage
